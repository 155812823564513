.admin{
    color: white;
    background-color: black;

}
.admin-container{
    max-width: 100vw;
    margin-top: 100px;
    min-height: 100vh;
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.admin-container h2{
    text-transform: capitalize;
}
.teams-overzicht-container{
    align-content: start;
    display: flex;

}
.team-details{
    background: white;
    border: black solid 1px;
    padding: 20px;
    margin: 20px;
}
.player-name{
    color: black;
    font-weight: bold;
}
.team-details td{
    border: grey solid 1px;
    margin: 5px;
    padding: 5px;
}
.score-form-container{
    padding: 20px;
    margin: 20px;
    max-width: 90vw;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*Mooie slider voor de page toggle*/

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 20px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: green;
}

/*input:focus + .slider {*/
/*    box-shadow: 0 0 1px red;*/
/*}*/

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.switchbox-loca{
    margin-bottom: 50px;
}
.punten-toevoeg-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
select option {
    padding: 5px;
    margin: 0 10px;
}
input[type="number"] {
    padding: 5px;
    width: 50px;
    font-size: 16px;
}