.special-code-page{
    min-height: 90vh;
    margin: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
}
.special-code-container{
    box-shadow: 5px 4px 18px #00000080;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
}
.code-background-teman{
    height: 100vh;
    width: 100vw;
    background-image: url("https://www.batikdlidir.com/wp-content/uploads/2020/03/IMG-20200206-WA0022.jpg");
    background-repeat: repeat;
    background-position: center;
}
.code-background-vuur{
    height: 100vh;
    width: 100vw;
    background-image: url("https://cdn.pixabay.com/photo/2021/12/16/03/38/fire-6873707_1280.jpg");
    background-repeat: repeat;
    background-position: center;
}
.code-background-krishna{
    height: 100vh;
    width: 100vw;
    background-image: url("https://storage.ning.com/topology/rest/1.0/file/get/3360552821?profile=RESIZE_1200x&width=1000");
    background-repeat: repeat;
    background-position: center;
}
.code-background-duisternis{
    height: 100vh;
    width: 100vw;
    background-color: black;
}
.code-background-duisternis p{
    color: #282c34;
}
.code-background-bosn{
    height: 100vh;
    width: 100vw;
    background-image: url("https://pbs.twimg.com/media/DclgtxxX0AAe4Pk.jpg");
    background-repeat: repeat;
    background-position: top;
}
.code-background-bosn .special-code-container{
    margin-top: 45%;
}
