.form-container{
    background-image: url("../../assets/MW 2023 - 1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
height: 100vh;
display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
}
.login-form{
    background-color: white;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    border: 1px solid #00000080;
    box-shadow: 5px 4px 18px #00000080;
    position: fixed;
}
.login-form button{
    color: black;
}