.signup-form-container{
    background-image: url("../../assets/MW 2023 - 3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-form h4{
    text-align: center;
}
.trust-issues-outer{
    margin-top: 20px;
    text-align: center;
}
.trust-issues h5{
    color: lightgrey;
    margin-bottom: 20px;
}