.quizbox{
    margin-top: 70px;
    padding-bottom: 70px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("../../assets/web mw seamless.jpeg");
    background-repeat: repeat;
    background-position: center;

    border: 1px solid #00000035;
    box-shadow: 5px 4px 18px #00000080;
}
.quizbox h3 {
    color: #282c34;
}
.quizbox h1{
    padding-top: 20px;
}

.quiz-container {
    padding: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
}
.quiz-inner-container-text{
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
}
.quiz{
}
.quiz button{
    background-color: white;
    color: #282c34;
    border: 1px solid currentColor;
    text-decoration: none;
    font-size: 20px;
    margin: 5px;
}
.quiz-button-container{
    background-color: white;
    padding: 5px;
    border: 1px solid #00000035;
    box-shadow: 5px 4px 18px #00000080;

    display: flex;
    flex-direction: column;
}
.quiz-button-container h3{
    padding: 5px;
}
.question{
    display: flex;
    flex-direction: column;
    /*border: 1px solid black;*/
    gap: 10px;
    padding: 10px;
    margin: 10px 0 10px 0;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #00000035;
    box-shadow: 5px 4px 18px #00000080;

}
.answers{

}
.quiz-punten-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    padding: 20px;
    margin: 20px;
    background-color: white;
    border: 1px solid #00000035;
    box-shadow: 5px 4px 18px #00000080;
}
.quiz-punten-container button{
    margin: 10px 0 0 0;
}