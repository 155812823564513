@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@500;800&family=Poppins:wght@600&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p{
  font-size: 18px;
}
@font-face {
  font-family: Elianto-Regular;
  src: url("Elianto-Regular.otf");
}
h1{
  font-family: "Elianto-Regular", sans-serif;
  text-transform: uppercase;
  font-size: calc(20px + 2vmin);
}
h2, h3{
  font-family: 'Poppins', sans-serif;
}
h4{
  font-family: 'Poppins', sans-serif;
  color: dimgrey;
}
p{
  font-family: 'Big Shoulders Display', cursive;
}
h5{
  color: grey;
  font-size: 12px;
  font-family: 'Big Shoulders Display', cursive;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
