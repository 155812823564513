
.team-Container{
    background-color: white;
    padding: 30px;
    box-shadow: 5px 4px 18px #00000080;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.team-inner-container{
    text-align: center;
}
.team-Container h3, h2 {
    color: #282c34;
    margin-left: 15px;
}
.team-details-container{
    margin: 40px 0 25px 0;
    padding: 25px 0 25px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-transform: capitalize;
    border-radius: 20px;
    box-shadow: 1px 1px 10px #00000080;
}
.team-details-container h1{
    padding: 0 25px 0 25px;
    color: white;
}
.team-details-container .team-points-container h3{
    color: white;
}
.team-details-container table{
    background-color: white;
}

.team-details-container table td{
    color: black;
}
.team-Container button {
    margin: 30px 0 20px 0;
    color: black;
    border: 2px black solid ;
}
.uitloggen{
    align-items: center;
    margin-top: 10px;
}
.uitloggen button{
    padding: 10px;
    margin-right: 20px;
}
.nick-bartkovski{
    min-height: 90vh;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
}