.locatie-pagina{
     background-color: #ffffff;
 }
.locatie-pagina h3 {
    color: #282c34;
}
.locatie-pagina a {
    text-decoration: none;
    color: currentColor;
}
.line1{
    border-top: 50px solid white;
    border-right: 100vw solid transparent;
    padding-bottom: 70px;
}

img {
    width: 100vw;
    height: auto;
}
.header {
    position: relative;
    margin-top: 20px;
    width: 100vw;
}
.header h1{
    padding-top: 5px;
    font-size: 2em;
    color: white;
}
.header-text {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
}
.locatie-text-container{
    display: flex;
    flex-direction: column;
}
.article-containers{
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgb(203, 203, 203) 0%, rgba(244,164,96,0.8029586834733894) 100%);
}
.adres p{
    font-size: 1em;
}
.article-containers p{
    font-size: 0.74em; /* use a relative unit */
    height: 100%;
    margin: 0;
}

/* adjust the font size at different screen widths */
@media screen and (min-width: 600px) {
    .locatie-pagina p, .adres {
        font-size: 1.2em;
    }
    .header h1{
        font-size: 3em;
    }
}

@media screen and (min-width: 900px) {
    .locatie-pagina p {
        font-size: 1.7em;
    }
    .header h1{
        font-size: 5em;
    }
}
.locatie-text-container img {
    width: 50%;
}
.locatie-text-container article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*border: 1px solid #00000035;*/
    box-shadow: 5px 4px 18px #00000080;
    margin-top: 30px;
    background-color: white;
    width: 90vw;
}
.locatie-text-container .andersom{
    display: flex;
    flex-direction: row-reverse;
}
.locatie-text-container article div{
    padding: 20px;
    width: 50%;
}
.info-container, .info-container-verblijf{
    padding: 30px 30px 70px 30px;
    max-width: 90vw;
}
.info-container h1{
    padding-bottom: 20px;
    text-align: center;
}
.info-container h3{
    padding-bottom: 20px;
}
/*.header-text::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: rgba(0, 0, 0, 0.2); !* Change the alpha value to adjust the transparency *!*/
/*    z-index: -1;*/
/*}*/
.info-container-verblijf {
    display: flex;
    flex-direction: column;
}
.info-inner-container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.padding-container{
    padding-top: 15px;
}