.body-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signup-message{
    color: black;
    height: 20vh;
}
.body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: calc(10px + 2vmin);
    width: 100vw;
    min-height: 72vh;
    margin: 50px 0 50px 0;

    background-image: url("../../assets/MW 2023 - 3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
}
.body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}
.line2{
    border-bottom: 50px solid white;
    border-right: 100vw solid transparent;
}

.home-header{
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
    text-align: center;
    max-width: 80vw;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 1px 4px 18px #00000080;
}
.home-header h1{
    font-size: calc(30px + 2vmin);
    padding: 30px;
}
.main-container{
    text-align: center;
}
.main-container a{
    color: currentColor;
}
.main-container-inner{
    padding: 30px;
    margin: 20vh 0 20vh 0;
}
.scores-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgb(218,218,218);
    background: linear-gradient(180deg, rgba(218,218,218,1) 0%, rgba(255,255,255,1) 83%);
}
.scores-outer-inner{
    padding: 22vh 0 22vh 0;
}
.scores-inner-container{
     display: flex;
     justify-content: center;
 }
.scores-inner-container h2, h3{
    color: white;
}
.blauw{
    background: rgb(6,59,184);
    background: linear-gradient(180deg, rgba(6,59,184,1) 0%, rgba(10,35,101,1) 100%);
    min-width: 100px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 1px 5px #282c34;
}
.rood{
    background: rgb(184,6,6);
    background: linear-gradient(180deg, rgba(184,6,6,1) 0%, rgba(101,10,10,1) 100%);
    min-width: 100px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 1px 5px #282c34;
}
.errors{
    color: red; /* red text */
    padding: 10px;
}

.button-container {
    position: fixed;
    bottom: 50px;
    right: 0;
}

.action-button {
    z-index: 1;
    color: white;
    position: fixed;
    bottom: 70px;
    right: 20px;
    padding: 10px;
    background-color: black;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 15px 15px 1px 15px;
    border: black;
    box-shadow: 1px 1px 10px #00000080;
    font-size: calc(10px + 2vmin);;
}

.action-button:hover {
    transform: scale(1.1);
}

.action-button span {
    display: block;
    line-height: 1;
}
.team-link{
    color: black;
    padding: 20px;
}
.team-link h3{
    color: black;
    padding-bottom: 15px;
}