
.inlogform-website{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}
.inlogform-website h2{
    text-align: center;
    color: white;
    padding: 20px;
}
