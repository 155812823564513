.footer{
    height: 5vh;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.footer p{
    color: white;
    font-size: 15px;
}
.footer a{
    text-decoration: none;
}
.footer h4{
    color: black;
}