
.navbar {
    z-index: 2;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    position: fixed;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    box-shadow: 0 1px 10px #282c34;
}
.icon{
    width: 8vw; /* adjust the size as needed */
    height: auto;
}
.navbar-container{
    margin: 20px;
}
.navbar-container ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    gap: 20px;
}
.gekke-knop {
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
   padding: 9px;
    background: white;
    border: 2px solid currentColor;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin-left: 10px;
}
ul .gekke-knop{
    color: #282c34;
}
.gekke-knop:hover{
    background-color: lightgray;
    transform: scale(1.1);
}
.navbar a {
    color: #282c34;
    text-decoration: none;
    font-size: 18px;
}

.navbar a:hover {
    color: lightgray;
}

.default-link {
    border-bottom: 1px solid transparent;
}
.active-link {
    border-bottom: 1px solid #282c34;
}

/*Responsive side*/
.hamburger-menu {
    display: none;
    color: #222;
    font-size: 2rem;
}

@media only screen and (max-width: 600px) {
{
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
}
.hamburger-menu {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    max-width: 6vh;
}
ul.collapsed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    overflow: hidden;
    max-height: 0;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

ul.collapsed.is-expanded {
     overflow: hidden;
     max-height: 500px;
     -moz-transition-duration: 0.4s;
     -webkit-transition-duration: 0.4s;
     -o-transition-duration: 0.4s;
     transition-duration: 0.4s;
     -moz-transition-timing-function: ease-in;
     -webkit-transition-timing-function: ease-in;
     -o-transition-timing-function: ease-in;
     transition-timing-function: ease-in;
 }
.navbar li {
    padding: 15px 10px;
    margin: 0 0;
    width: 100%;
}
}

#red{
    /*z-index: 2;*/
    background: rgb(184,6,6);
    background: linear-gradient(180deg, rgba(184,6,6,1) 0%, rgba(101,10,10,1) 100%);
}
#red a, .active-link{
    color: white;
}
#red .active-link{
    border-color: white;
}
#blue{
    /*z-index: 2;*/
    background: rgb(6,59,184);
    background: linear-gradient(180deg, rgba(6,59,184,1) 0%, rgba(10,35,101,1) 100%);
}
#blue a{
    color: white;
}