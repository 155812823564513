.score-form-container{
    color: white;
    padding: 40px 0 40px 0;
    text-decoration: none;font-size: calc(10px + 2vmin);
    background: rgb(47,47,47);
    background: radial-gradient(circle, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    max-width: 90vw;
    min-width: 30vw;
    box-shadow: 0 1px 10px #282c34;
    border-radius: 20px 20px 0 20px;
}

.score-form-container input[type="text"]{
    margin: 20px;
    padding: 10px;
    min-width: 300px;
}
.score-form-container button {
    color: #282c34;
}

section .red{
    background: rgb(252,46,46);
    background: linear-gradient(180deg, rgba(252,46,46,1) 0%, rgba(157,0,0,1) 100%);
}
section .blue{
    background: rgb(46,97,252);
    background: linear-gradient(180deg, rgba(46,97,252,1) 0%, rgba(0,7,157,1) 100%);
}
.score-form-errors{
    padding: 10px;
    color: white;
}
.score-form-container .errors{
    color: white;
}