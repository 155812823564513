.body-container-puntentelling{
    background-color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.body-container-puntentelling h1{
    padding-bottom: 10px;
}
.story{
    margin: 70px 0 70px 0;
    width: 90vw;
    color: white;
}
.story-container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: white;
    text-align: center;
}
.doelen-container{
    margin-top: 50px;
    padding: 0 20px 40px 20px;
}
.doelen-container ul {
    list-style-type: none;
}
.doelen-container li {
    color: black;
    border: solid black 2px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 1px 1px 5px #00000080;
}
.doelen-container a{
    text-decoration: none;
}
.white h4{
    color: white;
}
.teambutton-container{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #333333;
    padding: 40px 0 40px 0;
    margin: 0 20px 40px 20px;
    border-radius: 30px;
    box-shadow: 1px 1px 10px #00000080;

}
.story ul{
    padding: 0 20px 0 20px;
}
.story a{
    color: currentColor;
}

.story-container .red{
    color: white;
    background: rgb(184,6,6);
    background: linear-gradient(180deg, rgba(184,6,6,1) 0%, rgba(101,10,10,1) 100%);
}
.story-container .blue{
    color: white;
    background: rgb(6,59,184);
    background: linear-gradient(180deg, rgba(6,59,184,1) 0%, rgba(10,35,101,1) 100%);
}
.line3{
     border-left: 100vw solid  #333333;
     border-bottom: 50px solid transparent;
 }
.expanded-info p{
    color: dimgrey;
    padding: 10px 10px 10px 15px;
}
.expandable a{
    color: currentColor;
}