.message-board{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin: 80px 0 0 0;
    text-decoration: none;font-size: calc(10px + 2vmin);
    background: rgb(238,238,238);
    background: linear-gradient(180deg, rgba(238,238,238,1) 0%, rgba(213,213,213,1) 100%);
    box-shadow: 0 1px 10px #282c34;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
@media screen and (min-width: 600px) {
    li, p, span{
        font-size: 1.2em;
    }
    input{
        font-size: 0.7em;
    }
    .message-board, .end-container{
        max-width: 80vw;
    }
}
.berichten p{
    color: black;
}
.message-board input{
    margin: 20px;
    padding: 10px;
    min-width: 300px;
    min-height: 50px;
}
.message-board li{
    list-style-type: none;
    display: flex;
    gap: 10px;
    border: 1px solid #00000035;
    box-shadow: 0 1px 1px #00000080;
    align-items: center;
}
.user{
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: white;
    padding: 2px 10px 2px 10px;
    border-radius: 1px 15px 15px 15px;

}
.user-container{
    min-width: 70px;
    font-size: 18px;
    font-weight: bold;
}
.time {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    color: #aaa;
}
.white{
        color: grey;
    }
 .red{
        color: rgba(184,6,6,1);
    }
.blue{
        color:rgba(6,59,184,1);
    }

.berichten{
    font-size: 20px;
    font-family: "Helvetica Neue", serif;
    color: #282c34;
    padding: 15px;
}
.message-board form {
    background: rgb(47,47,47);
    background: radial-gradient(circle, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    padding: 40px 0 40px 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100%;
}
.berichten li {
    position: relative;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(245,245,245,0.8) 100%);
    margin: 10px;
    padding: 15px;
    border-radius: 1px 10px 10px 10px;
    text-align: start;
}
.message-board button {
    color: black;
}
.end-container{
    background: rgb(47,47,47);
    background: radial-gradient(circle, rgba(47,47,47,1) 0%, rgba(0,0,0,1) 100%);
    width: 100vw;
    border: black 2px solid;
    margin-bottom: 70px;
    padding: 30px;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 1px 10px #00000080;
}